var events = {exports: {}};

var R = typeof Reflect === 'object' ? Reflect : null;
var ReflectApply = R && typeof R.apply === 'function'
  ? R.apply
  : function ReflectApply(target, receiver, args) {
    return Function.prototype.apply.call(target, receiver, args);
  };

var ReflectOwnKeys;
if (R && typeof R.ownKeys === 'function') {
  ReflectOwnKeys = R.ownKeys;
} else if (Object.getOwnPropertySymbols) {
  ReflectOwnKeys = function ReflectOwnKeys(target) {
    return Object.getOwnPropertyNames(target)
      .concat(Object.getOwnPropertySymbols(target));
  };
} else {
  ReflectOwnKeys = function ReflectOwnKeys(target) {
    return Object.getOwnPropertyNames(target);
  };
}

function ProcessEmitWarning(warning) {
  if (console && console.warn) console.warn(warning);
}

var NumberIsNaN = Number.isNaN || function NumberIsNaN(value) {
  return value !== value;
};

function EventEmitter() {
  EventEmitter.init.call(this);
}
events.exports = EventEmitter;
events.exports.once = once;

// Backwards-compat with node 0.10.x
EventEmitter.EventEmitter = EventEmitter;

EventEmitter.prototype._events = undefined;
EventEmitter.prototype._eventsCount = 0;
EventEmitter.prototype._maxListeners = undefined;

// By default EventEmitters will print a warning if more than 10 listeners are
// added to it. This is a useful default which helps finding memory leaks.
var defaultMaxListeners = 10;

function checkListener(listener) {
  if (typeof listener !== 'function') {
    throw new TypeError('The "listener" argument must be of type Function. Received type ' + typeof listener);
  }
}

Object.defineProperty(EventEmitter, 'defaultMaxListeners', {
  enumerable: true,
  get: function() {
    return defaultMaxListeners;
  },
  set: function(arg) {
    if (typeof arg !== 'number' || arg < 0 || NumberIsNaN(arg)) {
      throw new RangeError('The value of "defaultMaxListeners" is out of range. It must be a non-negative number. Received ' + arg + '.');
    }
    defaultMaxListeners = arg;
  }
});

EventEmitter.init = function() {

  if (this._events === undefined ||
      this._events === Object.getPrototypeOf(this)._events) {
    this._events = Object.create(null);
    this._eventsCount = 0;
  }

  this._maxListeners = this._maxListeners || undefined;
};

// Obviously not all Emitters should be limited to 10. This function allows
// that to be increased. Set to zero for unlimited.
EventEmitter.prototype.setMaxListeners = function setMaxListeners(n) {
  if (typeof n !== 'number' || n < 0 || NumberIsNaN(n)) {
    throw new RangeError('The value of "n" is out of range. It must be a non-negative number. Received ' + n + '.');
  }
  this._maxListeners = n;
  return this;
};

function _getMaxListeners(that) {
  if (that._maxListeners === undefined)
    return EventEmitter.defaultMaxListeners;
  return that._maxListeners;
}

EventEmitter.prototype.getMaxListeners = function getMaxListeners() {
  return _getMaxListeners(this);
};

EventEmitter.prototype.emit = function emit(type) {
  var args = [];
  for (var i = 1; i < arguments.length; i++) args.push(arguments[i]);
  var doError = (type === 'error');

  var events = this._events;
  if (events !== undefined)
    doError = (doError && events.error === undefined);
  else if (!doError)
    return false;

  // If there is no 'error' event listener then throw.
  if (doError) {
    var er;
    if (args.length > 0)
      er = args[0];
    if (er instanceof Error) {
      // Note: The comments on the `throw` lines are intentional, they show
      // up in Node's output if this results in an unhandled exception.
      throw er; // Unhandled 'error' event
    }
    // At least give some kind of context to the user
    var err = new Error('Unhandled error.' + (er ? ' (' + er.message + ')' : ''));
    err.context = er;
    throw err; // Unhandled 'error' event
  }

  var handler = events[type];

  if (handler === undefined)
    return false;

  if (typeof handler === 'function') {
    ReflectApply(handler, this, args);
  } else {
    var len = handler.length;
    var listeners = arrayClone(handler, len);
    for (var i = 0; i < len; ++i)
      ReflectApply(listeners[i], this, args);
  }

  return true;
};

function _addListener(target, type, listener, prepend) {
  var m;
  var events;
  var existing;

  checkListener(listener);

  events = target._events;
  if (events === undefined) {
    events = target._events = Object.create(null);
    target._eventsCount = 0;
  } else {
    // To avoid recursion in the case that type === "newListener"! Before
    // adding it to the listeners, first emit "newListener".
    if (events.newListener !== undefined) {
      target.emit('newListener', type,
                  listener.listener ? listener.listener : listener);

      // Re-assign `events` because a newListener handler could have caused the
      // this._events to be assigned to a new object
      events = target._events;
    }
    existing = events[type];
  }

  if (existing === undefined) {
    // Optimize the case of one listener. Don't need the extra array object.
    existing = events[type] = listener;
    ++target._eventsCount;
  } else {
    if (typeof existing === 'function') {
      // Adding the second element, need to change to array.
      existing = events[type] =
        prepend ? [listener, existing] : [existing, listener];
      // If we've already got an array, just append.
    } else if (prepend) {
      existing.unshift(listener);
    } else {
      existing.push(listener);
    }

    // Check for listener leak
    m = _getMaxListeners(target);
    if (m > 0 && existing.length > m && !existing.warned) {
      existing.warned = true;
      // No error code for this since it is a Warning
      // eslint-disable-next-line no-restricted-syntax
      var w = new Error('Possible EventEmitter memory leak detected. ' +
                          existing.length + ' ' + String(type) + ' listeners ' +
                          'added. Use emitter.setMaxListeners() to ' +
                          'increase limit');
      w.name = 'MaxListenersExceededWarning';
      w.emitter = target;
      w.type = type;
      w.count = existing.length;
      ProcessEmitWarning(w);
    }
  }

  return target;
}

EventEmitter.prototype.addListener = function addListener(type, listener) {
  return _addListener(this, type, listener, false);
};

EventEmitter.prototype.on = EventEmitter.prototype.addListener;

EventEmitter.prototype.prependListener =
    function prependListener(type, listener) {
      return _addListener(this, type, listener, true);
    };

function onceWrapper() {
  if (!this.fired) {
    this.target.removeListener(this.type, this.wrapFn);
    this.fired = true;
    if (arguments.length === 0)
      return this.listener.call(this.target);
    return this.listener.apply(this.target, arguments);
  }
}

function _onceWrap(target, type, listener) {
  var state = { fired: false, wrapFn: undefined, target: target, type: type, listener: listener };
  var wrapped = onceWrapper.bind(state);
  wrapped.listener = listener;
  state.wrapFn = wrapped;
  return wrapped;
}

EventEmitter.prototype.once = function once(type, listener) {
  checkListener(listener);
  this.on(type, _onceWrap(this, type, listener));
  return this;
};

EventEmitter.prototype.prependOnceListener =
    function prependOnceListener(type, listener) {
      checkListener(listener);
      this.prependListener(type, _onceWrap(this, type, listener));
      return this;
    };

// Emits a 'removeListener' event if and only if the listener was removed.
EventEmitter.prototype.removeListener =
    function removeListener(type, listener) {
      var list, events, position, i, originalListener;

      checkListener(listener);

      events = this._events;
      if (events === undefined)
        return this;

      list = events[type];
      if (list === undefined)
        return this;

      if (list === listener || list.listener === listener) {
        if (--this._eventsCount === 0)
          this._events = Object.create(null);
        else {
          delete events[type];
          if (events.removeListener)
            this.emit('removeListener', type, list.listener || listener);
        }
      } else if (typeof list !== 'function') {
        position = -1;

        for (i = list.length - 1; i >= 0; i--) {
          if (list[i] === listener || list[i].listener === listener) {
            originalListener = list[i].listener;
            position = i;
            break;
          }
        }

        if (position < 0)
          return this;

        if (position === 0)
          list.shift();
        else {
          spliceOne(list, position);
        }

        if (list.length === 1)
          events[type] = list[0];

        if (events.removeListener !== undefined)
          this.emit('removeListener', type, originalListener || listener);
      }

      return this;
    };

EventEmitter.prototype.off = EventEmitter.prototype.removeListener;

EventEmitter.prototype.removeAllListeners =
    function removeAllListeners(type) {
      var listeners, events, i;

      events = this._events;
      if (events === undefined)
        return this;

      // not listening for removeListener, no need to emit
      if (events.removeListener === undefined) {
        if (arguments.length === 0) {
          this._events = Object.create(null);
          this._eventsCount = 0;
        } else if (events[type] !== undefined) {
          if (--this._eventsCount === 0)
            this._events = Object.create(null);
          else
            delete events[type];
        }
        return this;
      }

      // emit removeListener for all listeners on all events
      if (arguments.length === 0) {
        var keys = Object.keys(events);
        var key;
        for (i = 0; i < keys.length; ++i) {
          key = keys[i];
          if (key === 'removeListener') continue;
          this.removeAllListeners(key);
        }
        this.removeAllListeners('removeListener');
        this._events = Object.create(null);
        this._eventsCount = 0;
        return this;
      }

      listeners = events[type];

      if (typeof listeners === 'function') {
        this.removeListener(type, listeners);
      } else if (listeners !== undefined) {
        // LIFO order
        for (i = listeners.length - 1; i >= 0; i--) {
          this.removeListener(type, listeners[i]);
        }
      }

      return this;
    };

function _listeners(target, type, unwrap) {
  var events = target._events;

  if (events === undefined)
    return [];

  var evlistener = events[type];
  if (evlistener === undefined)
    return [];

  if (typeof evlistener === 'function')
    return unwrap ? [evlistener.listener || evlistener] : [evlistener];

  return unwrap ?
    unwrapListeners(evlistener) : arrayClone(evlistener, evlistener.length);
}

EventEmitter.prototype.listeners = function listeners(type) {
  return _listeners(this, type, true);
};

EventEmitter.prototype.rawListeners = function rawListeners(type) {
  return _listeners(this, type, false);
};

EventEmitter.listenerCount = function(emitter, type) {
  if (typeof emitter.listenerCount === 'function') {
    return emitter.listenerCount(type);
  } else {
    return listenerCount.call(emitter, type);
  }
};

EventEmitter.prototype.listenerCount = listenerCount;
function listenerCount(type) {
  var events = this._events;

  if (events !== undefined) {
    var evlistener = events[type];

    if (typeof evlistener === 'function') {
      return 1;
    } else if (evlistener !== undefined) {
      return evlistener.length;
    }
  }

  return 0;
}

EventEmitter.prototype.eventNames = function eventNames() {
  return this._eventsCount > 0 ? ReflectOwnKeys(this._events) : [];
};

function arrayClone(arr, n) {
  var copy = new Array(n);
  for (var i = 0; i < n; ++i)
    copy[i] = arr[i];
  return copy;
}

function spliceOne(list, index) {
  for (; index + 1 < list.length; index++)
    list[index] = list[index + 1];
  list.pop();
}

function unwrapListeners(arr) {
  var ret = new Array(arr.length);
  for (var i = 0; i < ret.length; ++i) {
    ret[i] = arr[i].listener || arr[i];
  }
  return ret;
}

function once(emitter, name) {
  return new Promise(function (resolve, reject) {
    function errorListener(err) {
      emitter.removeListener(name, resolver);
      reject(err);
    }

    function resolver() {
      if (typeof emitter.removeListener === 'function') {
        emitter.removeListener('error', errorListener);
      }
      resolve([].slice.call(arguments));
    }
    eventTargetAgnosticAddListener(emitter, name, resolver, { once: true });
    if (name !== 'error') {
      addErrorHandlerIfEventEmitter(emitter, errorListener, { once: true });
    }
  });
}

function addErrorHandlerIfEventEmitter(emitter, handler, flags) {
  if (typeof emitter.on === 'function') {
    eventTargetAgnosticAddListener(emitter, 'error', handler, flags);
  }
}

function eventTargetAgnosticAddListener(emitter, name, listener, flags) {
  if (typeof emitter.on === 'function') {
    if (flags.once) {
      emitter.once(name, listener);
    } else {
      emitter.on(name, listener);
    }
  } else if (typeof emitter.addEventListener === 'function') {
    // EventTarget does not have `error` event semantics like Node
    // EventEmitters, we do not listen for `error` events here.
    emitter.addEventListener(name, function wrapListener(arg) {
      // IE does not have builtin `{ once: true }` support so we
      // have to do it manually.
      if (flags.once) {
        emitter.removeEventListener(name, wrapListener);
      }
      listener(arg);
    });
  } else {
    throw new TypeError('The "emitter" argument must be of type EventEmitter. Received type ' + typeof emitter);
  }
}

class OfflineSyncer extends events.exports.EventEmitter {
    constructor() {
        super();
        window.emitRecv = (data) => this.emit('recv', data);
    }
    send(data) {
        console.log(data);
    }
}
class Collector {
    cache = [];
    todoTimer = 0;
    syncer;
    _onRecv;
    handleRecv = (...args) => {
        this._onRecv && this._onRecv(...args);
    };
    constructor(io) {
        this.syncLayout = io || new OfflineSyncer();
    }
    destroy() {
        this.syncer?.removeListener('recv', this.handleRecv);
        this.save();
        this._onRecv = undefined;
        this.syncer = undefined;
        clearTimeout(this.todoTimer);
        this.todoTimer = 0;
    }
    set syncLayout(syncer) {
        if (!syncer) {
            throw new Error(`sync layout can not be ${typeof syncer}`);
        }
        this.syncer?.removeListener('recv', this.handleRecv);
        this.syncer = syncer;
        this.syncer.addListener('recv', this.handleRecv);
    }
    set onRecv(fn) {
        this._onRecv = fn;
    }
    save() {
        const dataset = this.cache;
        this.cache = [];
        this.syncer?.send && this.syncer.send(dataset);
    }
    push(data) {
        this.cache.push(data);
        if (this.todoTimer)
            return;
        this.todoTimer = window.setTimeout(() => {
            clearTimeout(this.todoTimer);
            this.todoTimer = 0;
            this.save();
        }, 300);
    }
}

class Editor {
    elCanvas = document.createElement('canvas');
    _collector;
    ctx;
    _scale = window.devicePixelRatio;
    // 这里存储canvas使用的坐标，向外暴露的是单倍坐标值
    config = {
        fontSize: 32,
        family: 'serif',
        lineMargin: 1,
        color: '#333',
        bgColor: 'transparent'
    };
    _point = {
        x: 0,
        y: this.config.fontSize / 2 * 3 * (this.config.lineMargin - 1) / 2
    };
    _onCaretMove;
    constructor(sync) {
        this._collector = new Collector(sync);
        this._collector.onRecv = (payload) => this.draw({ ...payload, width: payload.width * this._scale });
        this.elCanvas.setAttribute('class', 'editor__canvas');
        const ctx = this.elCanvas.getContext('2d');
        if (!ctx)
            throw new Error('get canvas 2d context faild');
        this.ctx = ctx;
        this.elCanvas.addEventListener('resize', this.resize);
    }
    destroy() {
        console.warn('on editor destroy');
        this.elCanvas.removeEventListener('resize', this.resize);
        this._collector.destroy();
        document.removeChild(this.elCanvas);
    }
    set syncer(sync) {
        this._collector.syncLayout = sync;
    }
    get scale() {
        return this._scale;
    }
    set scale(s) {
        this._scale = s;
    }
    get fontSize() {
        return this.config.fontSize / this._scale;
    }
    set fontSize(s) {
        this.config.fontSize = s * this._scale;
        this._onCaretMove(this.point);
    }
    get lineMargin() {
        return this.config.lineMargin;
    }
    set lineMargin(h) {
        this.config.lineMargin = Math.max(1, h);
    }
    get bgColor() {
        return this.config.bgColor;
    }
    set bgColor(c) {
        this.config.bgColor = c;
    }
    get canvas() {
        return this.elCanvas;
    }
    get point() {
        const { x, y } = this._point;
        return {
            x: x / this._scale,
            y: y / this._scale,
            height: this.config.fontSize / 2 * 3 / this._scale
        };
    }
    set point(p) {
        const halfLineHeight = this.config.fontSize / 4 * 3;
        const x = Math.min(this._point.x, p.x * this._scale);
        // 点击点应该是行的中间高度
        const y = Math.min(this._point.y, p.y * this._scale - halfLineHeight);
        this._point = { x, y };
        const timer = setTimeout(() => {
            clearTimeout(timer);
            this._onCaretMove(this.point);
        }, 0);
    }
    set onCaretMove(fn) {
        this._onCaretMove = fn;
    }
    draw({ txt, width, paragraph }) {
        const { ctx, _point } = this;
        if (!ctx)
            return;
        let x, y;
        const { fontSize = 32, lineMargin } = this.config;
        const lineHeight = fontSize / 2 * 3;
        const baseLine = _point.y + fontSize;
        // enter key
        if ('\n' === txt) {
            x = 0;
            y = _point.y + lineHeight * lineMargin;
        }
        else {
            ctx.fillStyle = this.config.bgColor;
            ctx.fillRect(_point.x, _point.y, width, lineHeight);
            ctx.fillStyle = this.config.color;
            ctx.fillText(txt, _point.x, baseLine, width);
            x = _point.x + width;
            y = _point.y;
        }
        this._point = { x, y };
        this._onCaretMove(this.point);
    }
    resize() {
        let changed = false;
        const { elCanvas, config } = this;
        if ((elCanvas?.clientWidth || 0) * this._scale !== elCanvas.width) {
            elCanvas.width = (elCanvas?.clientWidth || 0) * this._scale;
            changed = true;
        }
        if ((elCanvas?.clientHeight || 0) * this._scale !== elCanvas.height) {
            elCanvas.height = (elCanvas?.clientHeight || 0) * this._scale;
            changed = true;
        }
        if (!changed)
            return;
        this.ctx.font = `${config.fontSize}px ${config.family}`;
    }
    write(str) {
        const ctx = this.ctx;
        if (!ctx)
            return;
        const list = str.split('\n');
        list.forEach((txt, idx) => {
            if (txt) {
                const payload = { txt, width: ctx.measureText(txt).width, paragraph: 0 };
                this.draw(payload);
                this._collector.push({ ...payload, width: payload.width / this._scale });
            }
            if (idx < list.length - 1) {
                const payload = { txt: '\n', width: 0, paragraph: 0 };
                this.draw(payload);
                this._collector.push(payload);
            }
        });
    }
}

const style = `
  @keyframes flash {
    0% {
      opacity: 1;
    }
    20% {
      opacity: 1;
    }
    30% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  .inputer {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .inputer .inputer__caret {
    width: 1px;
    height: 50%;
    animation: flash 1000ms linear infinite;
    background: #000;
  }

  .inputer .inputer__cache {
    width: 1px;
    height: 1px;
    transform: rotateX(90deg);
    font-size: 1px;
  }
`;
class CaretInputer {
    selfElement = document.createElement('div');
    // 输入缓存
    inputElement = document.createElement('div');
    // 是否开启输入法
    compositionRef = false;
    _onInput;
    constructor(className = '', fn) {
        this._onInput = fn;
        this.selfElement.setAttribute('class', `inputer ${className}`);
        const caret = document.createElement('div');
        caret.setAttribute('class', 'inputer__caret');
        this.inputElement.setAttribute('class', 'inputer__cache');
        this.inputElement.setAttribute('tabIndex', '0');
        this.inputElement.setAttribute('contenteditable', 'true');
        this.inputElement.setAttribute('suppresscontenteditablewarning', 'suppresscontenteditablewarning');
        this.inputElement.oninput = ev => this._handleInput(ev);
        this.inputElement.onkeydown = ev => this._handleKeyDown(ev);
        this.inputElement.addEventListener('compositionstart', (ev) => this._handleComposition(ev, true));
        this.inputElement.addEventListener('compositionend', (ev) => this._handleComposition(ev, false));
        this.selfElement.appendChild(caret);
        this.selfElement.appendChild(this.inputElement);
    }
    destroy() {
        this._onInput = undefined;
        this.inputElement.remove();
        this.selfElement.remove();
    }
    get element() {
        return this.selfElement;
    }
    set onInput(fn) {
        this._onInput = fn;
    }
    _follow(inputType, str) {
        switch (inputType) {
            case 'insertParagraph':
                str = '\n';
        }
        console.log(`inputType: ${inputType}, str: ${str}`);
        this._onInput(str);
        this.inputElement.innerHTML = '';
    }
    _handleInput(ev) {
        if (this.compositionRef)
            return;
        const { inputType, data } = ev;
        if ('insertCompositionText' === inputType)
            return;
        this._follow(inputType, data);
    }
    _handleComposition(ev, enter) {
        this.compositionRef = enter;
        if (enter)
            return;
        this._follow('insertText', ev.data);
    }
    _handleKeyDown(ev) {
        console.log(ev);
        switch (ev.keyCode) {
            case 8: // backspace
                this._follow('backspace', '');
                break;
        }
    }
    focus(p) {
        const styl = this.selfElement.style;
        styl.left = `${p.x || 0}px`;
        styl.top = `${p.y || 0}px`;
        styl.height = `${p.height || 16}px`;
        this.inputElement.focus();
    }
}

class EditorView extends HTMLElement {
    elWrapper = document.createElement('div');
    elInputer = new CaretInputer('editor__inputer');
    editorRef = new Editor(new OfflineSyncer());
    elStyle = document.createElement('style');
    constructor() {
        super();
        this.elWrapper.setAttribute('class', 'editor');
        this.elWrapper.onclick = ev => this._handleClick(ev);
        this.elInputer.onInput = (str) => this.editorRef.write(str);
        this.editorRef.onCaretMove = (p) => this.elInputer.focus(p);
        this.editorRef.resize();
        this.editorRef.bgColor = '#f00';
        const shadow = this.attachShadow({ mode: 'closed' });
        this.elWrapper.appendChild(this.elInputer.element);
        this.elWrapper.appendChild(this.editorRef.canvas);
        shadow.appendChild(this.elStyle);
        shadow.appendChild(this.elWrapper);
        this.elStyle.innerHTML = `
      ${style}

      .editor {
        display: flex;
        position: relative;
        outline: 0;
        box-sizing: border-box;
        margin: 0 auto;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow: auto;
        text-align: left;
        background: #fff;
      }

      .editor canvas.editor__canvas {
        width: 100%;
        height: 100%;
      }

      .editor .editor__inputer {
        position: absolute;
        top: 0;
        left: 0;
      }
    `;
    }
    destroy() {
        console.warn('on editor unmount');
        this.editorRef.destroy();
        this.elInputer.destroy();
        this.elStyle.remove();
        this.elWrapper.remove();
    }
    _setPageSize(width, height) {
        this.elWrapper.style.width = width;
        this.elWrapper.style.height = height;
        this.editorRef.resize();
    }
    _setLineHeight(margin = 1) {
        this.editorRef.lineMargin = margin;
    }
    _setFontSize(fontSize = 32) {
        this.editorRef.fontSize = fontSize;
    }
    _handleClick(ev) {
        const x = Math.max(ev.offsetX - 4, 0);
        const y = ev.offsetY;
        this.editorRef.point = { x, y };
    }
    ;
    change(attr, val) {
        switch (attr) {
            case 'pageSize':
                return this._setPageSize(val.width, val.height);
            case 'fontSize':
                return this._setFontSize(val);
            case 'lineHeight':
                return this._setLineHeight(val);
            default:
                console.warn(attr, val);
        }
    }
}
customElements.define('editor-view', EditorView);

export { Collector, Editor, OfflineSyncer, EditorView as default };
